import { useState } from 'react';
import { useLazyGetPropertyQuery } from '../../store/slices/apiSlice/scout-api-slice';

function useGetPropertiesDetails() {
    const [details, setDetails] = useState({});
    const [getProperty] = useLazyGetPropertyQuery();

    const fetchPropertiesDetails = (propIds, fields) => {
        propIds.forEach((id) => fetchDetails(id, fields));
    };

    const fetchDetails = (propId, fields) => {
        getProperty(propId)
            .unwrap()
            .then((fulfilled) => {
                // test on fulfilled should not be needed anymore but leaving it in for safety.
                // it was previously required for side effect when put scout invalidated a pending get scout...
                if (fulfilled) {
                    const fieldsData = {};
                    fields.forEach((f) => (fieldsData[f] = fulfilled[f]));
                    setDetails((h) => ({ ...h, [propId]: fieldsData }));
                }
            })
            .catch((rejected) => console.error(propId, rejected));
    };

    return [details, fetchPropertiesDetails];
}

export default useGetPropertiesDetails;
